import { AccountCircleOutlined, SyncAltOutlined } from "@material-ui/icons/";
import Profil from "../views/Profil/Profil";
import ProfileDelegations from "../module/profileDelegation/ProfileDelegations"
//lingui
import { Trans } from "@lingui/macro";
import React from "react";

const profilRoutes = [
  {
    path: "/profile/myProfile",
    name: <Trans> My Profile</Trans>,
    short: <Trans>My Profile</Trans>,
    icon: AccountCircleOutlined,
    component: Profil
  },
  {
    path: "/profile/profileDelegations",
    name: <Trans>Profile Delegations</Trans>,
    short: <Trans>Profile Delegations</Trans>,
    icon: SyncAltOutlined,
    component: ProfileDelegations
  }
];

export default profilRoutes;