import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stepper, Step, StepButton, CircularProgress } from "@material-ui/core";
import Button from "../CustomButtons/Button.jsx";

// Card components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";

import { Trans } from "@lingui/macro";

// style for this view
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { isArrayNullOrEmpty, isNull } from "../../tools.jsx";

function Wizard({ title, steps, handleClose, handleSave, checkClose, cardWarning, showCard, classes }) {
    const [activeStep, setActiveStep] = useState(0);
    const [showCloseWithoutSave, setShowCloseWithoutSave] = useState(false);
    const [btnNextLoading, setBtnNextLoading] = useState(false);
    const [btnPreviousLoading, setBtnPreviousLoading] = useState(false);
    steps = steps.filter(s=>s.hidden === undefined || s.hidden === false);
    if (isArrayNullOrEmpty(steps)) {
        return (<span>Steps are empty</span>);
    }

    var currentStep = steps[activeStep];

    function handlePrevious() {
        if (!currentStep.validate) {
            setActiveStep(activeStep - 1);
            return;
        }
        setBtnPreviousLoading(true);

        currentStep.validate((success) => {
            setBtnPreviousLoading(false);
            if (success) setActiveStep(activeStep - 1);
        }, !isNull(cardWarning));
    }

    function handleNext() {
        if (!currentStep.validate) {
            setActiveStep(activeStep + 1);
            return;
        }
        setBtnNextLoading(true);

        currentStep.validate((success) => {
            setBtnNextLoading(false);
            if (success) setActiveStep(activeStep + 1);
        }, !isNull(cardWarning));
    }

    function close() {
        (!checkClose || checkClose()) ? handleClose() : setShowCloseWithoutSave(true);
    }

    var buttonPrev = (<></>);
    if (activeStep > 0) {
        buttonPrev = btnPreviousLoading ? <CircularProgress /> : <Button type="submit" color="info" onClick={handlePrevious}><Trans>Previous</Trans></Button>;
    }

    var buttonNext = (<></>);
    if (activeStep < steps.length - 1) {
        buttonNext = btnNextLoading ? <CircularProgress /> : <Button type="submit" color="info" onClick={handleNext}><Trans>Next</Trans></Button>;
    }

    var buttonSave = (<></>);
    if (activeStep === steps.length - 1)
        buttonSave = <Button type="submit" color="info" onClick={() => handleSave()}><Trans>Save</Trans></Button>;


    if(showCard) return (
        <>
            <Card
                maxWidth='md'
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialogPaper }}
                scroll="paper">
                <CardHeader>{title}</CardHeader>
                <CardBody>
                    <div>
                        <Stepper activeStep={activeStep}>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepButton onClick={() => setActiveStep(index)}>
                                        {step.title}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    {cardWarning}
                    {currentStep.content()}
                </CardBody>
                <CardFooter>
                    {buttonPrev}
                    {buttonNext}
                    {buttonSave}
                    <Button type="submit" color="info" onClick={() => close()}><Trans>Abort</Trans></Button>
                </CardFooter>
            </Card>
            <Dialog
                open={showCloseWithoutSave}
            >
                <DialogContent>
                    <DialogContentText>
                        <Trans> ConfirmCloseWithoutSave </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="info">
                        <Trans> Yes </Trans>
                    </Button>
                    <Button onClick={() => setShowCloseWithoutSave(false)}>
                        <Trans> No </Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );    
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={true}
                onClose={() => close()}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialogPaper }}
                scroll="paper">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <div>
                        <Stepper activeStep={activeStep}>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepButton onClick={() => setActiveStep(index)}>
                                        {step.title}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    {cardWarning}
                    {currentStep.content()}
                </DialogContent>
                <DialogActions>
                    {buttonPrev}
                    {buttonNext}
                    {buttonSave}
                    <Button type="submit" color="info" onClick={() => close()}><Trans>Abort</Trans></Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showCloseWithoutSave}
            >
                <DialogContent>
                    <DialogContentText>
                        <Trans> ConfirmCloseWithoutSave </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="info">
                        <Trans> Yes </Trans>
                    </Button>
                    <Button onClick={() => setShowCloseWithoutSave(false)}>
                        <Trans> No </Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withStyles(thirdPartySearchCriteriaStyle)(Wizard);