import { Trans } from "@lingui/macro";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import CardError from "components/Card/CardError";
import FieldText from "components/Common/FieldText";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useState } from "react";
import { GroupGet, GroupGetAll } from "./actions/Group.Actions";
import GroupTree from "./part/detail/GroupTree";

const GroupSelect = ({ groupId, setGroupId, withRoot, isEditable, label, error }) => {
  const [dialogBox, setDialogBox] = useState(null);
  const [group, setGroup] = useState(null);
  const [loadError, setLoadError] = useState(null);

  useEffect(() => {
    if (dialogBox) setDialogBox(null);
    if (!!withRoot && groupId === 0) {
      setGroup({ id: 0 });
    } else if (groupId > 0 && group?.id !== groupId) {
      GroupGet(groupId)
        .then(g => setGroup(g))
        .catch(setLoadError);
    } else {
      setGroup({ id: 0 });
    }
  }, [groupId]);

  if (loadError) return <CardError error={loadError} />;
  if (!group) return <CircularProgress />;
  return (
    <Grid container>
      <Grid item xs={8}>
        <FieldText label={label} value={group.label} error={error} showErrorTooltip={true} isEditable={true} disabled={true} />
      </Grid>
      <Grid item xs={4}>
        {!!isEditable && (
          <>
            <Button
              onClick={() =>
                setDialogBox({
                  type: "cancel",
                  message: <Trans>Group_DialogSelectGroup</Trans>,
                  content: <GroupSearch groupId={groupId} setGroupId={setGroupId} />
                })
              }
            >
              <Trans>Group_SelectValue</Trans>
            </Button>
            {dialogBox && <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />}
          </>
        )}
      </Grid>
    </Grid>
  );
};

const GroupSearch = ({ groupId, setGroupId }) => {
  const [groups, setGroups] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!groups) {
      GroupGetAll()
        .then(g => setGroups(g))
        .catch(e => setError(e));
    }
  }, [groups]);

  if (error) return <CardError error={error} />;
  if (!groups) return <CircularProgress />;

  return (
    <>
      <GroupTree groups={groups} groupId={groups.find(g => g.id === groupId)} selectGroup={g => setGroupId(g.id)} />
    </>
  );
};

export default GroupSelect;
