import { Trans } from "@lingui/macro";
import { CircularProgress, Tab, Tabs } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Alert } from "@material-ui/lab";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import { TabPanel } from "components/Common/Tabs";
import React, { useEffect, useState } from "react";
import {
  JobActivityLevel1,
  JobActivityLevel2,
  LoadMasterValues,
  LocationCharacteristicType,
  LocationCharacteristicValue,
  LocationContactType,
  LocationStatus,
  LocationType
} from "store/MasterValue/MasterValueTypes";
import SiteGeneral from "../components/SiteGeneral";
import SiteMap from "../components/SiteMap";
import SiteStructure from "../components/SiteStructure";

const SiteDetailContent = ({ site, setSite, isEditable, openStructure, defaultLang, classes, errors }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const [isLoading, setIsLoading] = useState("init");

  useEffect(() => {
    if (isLoading === "init") {
      setIsLoading("true");
      LoadMasterValues([
        JobActivityLevel1,
        JobActivityLevel2,
        LocationType,
        LocationStatus,
        LocationCharacteristicType,
        LocationCharacteristicValue,
        LocationContactType
      ]).then(() => setIsLoading(null));
    }
  }, []);

  if (!!isLoading) {
    return <CircularProgress />;
  }

  const buildTabs = [
    {
      title: <Trans>Site General</Trans>,
      component: "SiteGeneral",
      status: "neutral"
    },
    {
      title: <Trans>Site Structure</Trans>,
      component: "SiteStructure",
      status: "neutral"
    },
    {
      title: <Trans>Site Map</Trans>,
      component: "SiteMap",
      status: "neutral"
    }
  ];

  let tabStatus = buildTabs;
  let tabs = tabStatus.map(tab => {
    var tabButton = <span>{tab.title}</span>;
    var tabContent = "";

    switch (tab.component) {
      case "SiteGeneral":
        var star = "";
        var saveStatusIcon = ""; //GetSaveStatusIcon(siteSaveStatus);
        var warningIcon = /*siteHasWarnings ? (<Warning style={{ 'color': 'orange' }} />) : */ "";
        //if (siteHasChanged) star = ' *';
        tabButton = (
          <span>
            {tab.title}
            {star}
          </span>
        );
        tabContent = (
          <SiteGeneral
            site={site}
            setSite={setSite}
            classes={classes}
            defaultLang={defaultLang}
            isEditable={isEditable && !site.isLocked}
            errors={errors}
          />
        );
        break;
      case "SiteStructure":
        var star = "";
        var saveStatusIcon = ""; //GetSaveStatusIcon(siteSaveStatus);
        var warningIcon = /*siteHasWarnings ? (<Warning style={{ 'color': 'orange' }} />) : */ "";
        //if (siteStructureHasChanged) star = ' *';
        tabButton = (
          <span>
            {saveStatusIcon}
            {warningIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <SiteStructure site={site} setSite={setSite} isEditable={isEditable && !site.isLocked} openStructure={openStructure} />;
        break;
      case "SiteMap":
        tabButton = <span>{tab.title}</span>;
        tabContent = <SiteMap site={site} setSite={setSite} />;
        break;
      default:
        break;
    }

    return {
      tabButton: tabButton,
      tabContent: tabContent,
      tabName: tab.component
    };
  });

  let lockedBanner = <></>;
  if (site.isLocked) {
    lockedBanner = (
      <Alert severity="warning">
        <Trans> WF_LockedUntilApprobation </Trans>
      </Alert>
    );
  }

  return (
    <>
      {lockedBanner}
      <Tabs variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Tabs location">
        {tabs.map((t, idx) => (
          <Tab key={"siteTab" + idx} label={t.tabButton} />
        ))}
      </Tabs>
      {tabs.map((t, idx) => (
        <TabPanel value={tabIdx} index={idx} id="siteTab">
          {t.tabContent}
        </TabPanel>
      ))}
    </>
  );
};

export default withStyles(structureStyle)(SiteDetailContent);
