import { ApimGet, ApimPost, ApimPut } from "components/Common/ApimSender";
import { isArray } from "tools";

export function SiteGet(siteId, callbackFn, errorFn) {
  ApimGet("api/location/" + siteId, c => callbackFn(c), e => errorFn(e));
}

export function SiteGetDetailed(siteId, callbackFn, errorFn) {
  ApimGet("api/location/" + siteId + "/detailed", c => callbackFn(c), e => errorFn(e));
}

export function SiteUpdate(site, callbackFn, errorFn, validationErrorFn) {
  ApimPut(
    "api/location/" + site.location.id,
    site,
    c => callbackFn(c),
    e => errorFn(e),
    e => (e.response.data && isArray(e.response.data) ? validationErrorFn(e.response.data) : errorFn(e))
  );
}

export function SiteCreate(site, callbackFn, errorFn, validationErrorFn) {
  ApimPost(
    "api/location/",
    site,
    c => callbackFn(c),
    e => errorFn(e),
    e => (e.response.data && isArray(e.response.data) ? validationErrorFn(e.response.data) : errorFn(e))
  );
}

export function GetCharacteristicValues(locationType, callbackFn, errorFn) {
  ApimGet("api/Location/charTypes/" + locationType, c => callbackFn(c), e => errorFn(e));
}

export function GetSiteHistory(siteId, callbackFn, errorFn) {
  ApimGet("api/location/" + siteId + "/histories", c => callbackFn(c), e => errorFn(e));
}
