import { Trans } from "@lingui/macro";
import { Button, Checkbox, CircularProgress, FormControlLabel, FormLabel, Switch, TextField, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Add, Check, Warning } from "@material-ui/icons";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { CommonCountry, StructureErt, StructureUe, TpCoupaActivity } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from "../../../tools";
import CardError from "components/Card/CardError";
import ContactCard from "./ContactCard";
import DialogCoupaContainGroup from "./DialogCoupaContainGroup";

const ComponentCoupa = function({
  isEditable,
  coupa,
  supplierCountryCode,
  onGetCoupa,
  onValueChanged,
  coupaActivities,
  countries,
  erts,
  ues,
  defaultLang,
  error,
  classes
}) {
  var [isFirstTime, setIsFirstTime] = useState(true);
  var [openDialog, setOpenDialog] = useState(false);
  var currentCountry = countries.find(c => c.codeIso2 === supplierCountryCode);
  var supplierCountryPhoneCode = !isNull(currentCountry) ? currentCountry.defaultPhoneCode : "00";

  useEffect(() => {
    if (isFirstTime && isNullOrEmpty(error)) {
      //dispatch(Actions.CoupaSupplierGet(supplier.thirdPartyIdentifier));
      onGetCoupa();
      setIsFirstTime(false);
    }
  });

  var errorEmailBuyer = null;
  var errorContact = [];
  if (!isNullOrEmpty(error)) {
    if (isArrayNullOrEmpty(error.response.data) || !error.response.data.some(d => d.severity !== "Error")) {
      return <CardError error={error} />;
    } else {
      var errorData = error.response.data;
      for (var i = 0; i < errorData.length; i++) {
        if (errorData[i].code === "COUPA_EMPTY_MAILBUYER" || errorData[i].code === "COUPA_INVALID_MAILBUYER") {
          errorEmailBuyer = errorData[i];
        }
        if (errorData[i].code === "COUPA_INVALID_MAILCONTACT") {
          errorContact = [...errorContact, { ...errorData[i], code: "MAIL" }];
        }
        if (errorData[i].code === "COUPA_INVALID_PHONECONTACT") {
          errorContact = [...errorContact, { ...errorData[i], code: "PHONE" }];
        }
      }
    }
  }

  function convertGroupToReactTable() {
    if (isNull(coupa) || isArrayNullOrEmpty(coupa.containGroups)) return [];

    return coupa.containGroups.map(containGroup => {
      if (isNull(containGroup)) return null;

      var codePays = countries.find(country => containGroup.codePays === country.codeIso2);
      if (isNull(codePays)) {
        codePays = containGroup.codePays;
      } else {
        codePays = codePays.codeIso2 + " - " + GetMasterValueLabel(codePays, defaultLang);
      }

      var codeFiliale = containGroup.codeFiliale;
      if (!isNullOrEmpty(codeFiliale)) {
        codeFiliale = erts.find(ert => codeFiliale === ert.oldIdentifier);
        if (isNull(codeFiliale)) {
          return null;
        }

        codeFiliale = codeFiliale.oldIdentifier + " - " + codeFiliale.label;
      }

      var codeRegion = containGroup.codeRegion;
      if (!isNullOrEmpty(codeRegion)) {
        codeRegion = ues.find(ue => codeRegion === ue.oldIdentifier);
        if (isNull(codeRegion)) {
          return null;
        }

        codeRegion = codeRegion.oldIdentifier + " - " + codeRegion.label;
      }

      var codeActivite = containGroup.codeActivite;
      if (!isNullOrEmpty(codeActivite)) {
        codeActivite = coupaActivities.find(act => codeActivite === act.code);
        if (isNull(codeActivite)) {
          return null;
        }

        codeActivite = codeActivite.code + " - " + GetMasterValueLabel(codeActivite, defaultLang);
      }

      return {
        code: containGroup.code,
        codePays: codePays,
        codeFiliale: codeFiliale,
        codeRegion: codeRegion,
        codeActivite: codeActivite
      };
    });
  }

  function addGroup() {
    setOpenDialog(true);
  }

  var isSupplierPunchout = !isNull(coupa) && coupa.bcSendingMethod === 3 && coupa.bcUpdateSendingMethod === 3;
  var coupaContact = "";
  var coupaContainGroupDialog = "";
  if (isNull(coupa)) {
    return (
      <center>
        <p>
          <Trans>Loading in progress</Trans>
        </p>
        <p>
          <CircularProgress />
        </p>
      </center>
    );
  }
  coupaContact = (
    <ContactCard
      id="CoupaContact"
      contact={coupa.contact}
      isEditable={isEditable && !isSupplierPunchout}
      isDeletable={false}
      isSwitchable={false}
      customHeader={
        <span>
          <Trans>Supplier contact</Trans>
        </span>
      }
      lockDetailTypes={true}
      countryPhoneCode={supplierCountryPhoneCode}
      onContactChange={c => {
        coupa = { ...coupa, contact: c };
        onValueChanged({ ...coupa, contact: c });
      }}
      requiredDetailTypeCodes={["MAIL", "PHONE"]}
      requiredDetailTitle={false}
      errorCodes={errorContact}
    />
  );
  coupaContainGroupDialog = (
    <DialogCoupaContainGroup
      id="CoupaContainGroupDialog"
      openDialog={openDialog}
      setOpenDialog={v => setOpenDialog(v)}
      onContainGroupChanged={containGroups => onValueChanged({ ...coupa, containGroups: containGroups })}
      selectedContainGroups={
        isArrayNullOrEmpty(coupa.containGroups)
          ? []
          : coupa.containGroups.map(c => {
              return { ...c, action: isNullOrEmpty(c.action) ? "EXIST" : c.action };
            })
      }
      defaultLang={defaultLang}
    />
  );

  var btnAddGroup = "";
  if (isEditable) {
    btnAddGroup = (
      <Button id="CoupaAddGroupButton" aria-label="Add" onClick={() => addGroup()}>
        <Add />
        &nbsp;<Trans>Add</Trans>
      </Button>
    );
  }

  var filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return !isNull(row[id]) ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
  };

  var isBcSendingMethodAuto = isNull(coupa) ? false : coupa.bcSendingMethod === 1 || coupa.bcSendingMethod === 3;
  var isBcUpdateSendingMethodAuto = isNull(coupa) ? false : coupa.bcUpdateSendingMethod === 1 || coupa.bcUpdateSendingMethod === 3;

  return (
    <div>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <GridContainer>
            <GridItem xs={9} sm={9} md={9}>
              <h4>
                <Trans>Contain Group</Trans>
              </h4>
            </GridItem>
            <GridItem xs={3} sm={3} md={3}>
              {btnAddGroup}
            </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <ReactTable
            id="CoupaExistingContainGroups"
            data={convertGroupToReactTable().filter(e => !isNull(e))}
            columns={[
              {
                Header: <Trans>Country</Trans>,
                accessor: "codePays",
                sortable: true,
                filterable: true
              },
              {
                Header: <Trans>Filiale</Trans>,
                accessor: "codeFiliale",
                sortable: true,
                filterable: true
              },
              {
                Header: <Trans>Region</Trans>,
                accessor: "codeRegion",
                sortable: true,
                filterable: true
              },
              {
                Header: <Trans>Activity</Trans>,
                accessor: "codeActivite",
                sortable: true,
                filterable: true
              }
            ]}
            defaultPageSize={5}
            showPaginationBottom={true}
            showPageSizeOptions={false}
            className="-striped -highlight"
            showFilters={true}
            defaultFilterMethod={filterMethod}
            style={ReactTableStyle.main}
          />
        </CardBody>
      </Card>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <Card
            style={{
              ...CardInlineStyle.card
            }}
          >
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <h4>
                <Trans>Supplier</Trans>
                {isSupplierPunchout ? (
                  <Tooltip style={{ float: "right", color: "yellow" }} title="Punchout (cXML)">
                    <Warning />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <Autocomplete
                  value={
                    isNull(coupa) || isArrayNullOrEmpty(coupa.emailBuyer)
                      ? []
                      : coupa.emailBuyer.map(v => {
                          return { inputValue: v };
                        })
                  }
                  disabled={!isEditable || isSupplierPunchout}
                  id="CoupaSupplierMail"
                  onChange={(e, newValue) => onValueChanged({ ...coupa, emailBuyer: newValue.map(v => v.inputValue) })}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  multiple
                  getOptionLabel={option => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option;
                  }}
                  options={[]}
                  renderOption={option => option.title}
                  renderInput={params => (
                    <TextField {...params} variant="standard" label={<Trans>Supplier Mail</Trans>} fullWidth error={errorEmailBuyer} />
                  )}
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      checked={isNull(coupa) ? false : coupa.waitingForBuyer}
                      disabled={!isEditable || isSupplierPunchout}
                      onChange={e => onValueChanged({ ...coupa, waitingForBuyer: e.target.checked })}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={<Trans>Waiting for buyer</Trans>}
                />
              </div>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Sending BC</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <span>
                    <Trans>Manual</Trans>
                    <Switch
                      tabIndex={-1}
                      checked={isBcSendingMethodAuto}
                      disabled={!isEditable || isSupplierPunchout}
                      onChange={e => onValueChanged({ ...coupa, bcSendingMethod: e.target.checked ? 1 : 2 })}
                    />
                    <Trans>Auto</Trans>
                  </span>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Update BC</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <span>
                    <Trans>Manual</Trans>
                    {/* 3 : Automatique ; 2 : Manuel */}
                    <Switch
                      tabIndex={-1}
                      checked={isBcUpdateSendingMethodAuto}
                      disabled={!isEditable || isSupplierPunchout}
                      onChange={e => onValueChanged({ ...coupa, bcUpdateSendingMethod: e.target.checked ? 1 : 2 })}
                    />
                    <Trans>Auto</Trans>
                  </span>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          {coupaContact}
        </GridItem>
      </GridContainer>
      {coupaContainGroupDialog}
    </div>
  );
};

const filter = createFilterOptions();

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    coupaActivities: state.MasterValueReducer[TpCoupaActivity],
    countries: state.MasterValueReducer[CommonCountry],
    erts: state.MasterValueReducer[StructureErt],
    ues: state.MasterValueReducer[StructureUe]
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(ComponentCoupa));
