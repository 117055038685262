import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";
import { Trans } from "@lingui/macro";
import PartThirdParties from "../common/PartThirdParties";
import PartLocations from "../common/PartLocations";
import FieldText from "components/Common/FieldText";
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import { Domain, LocationOn } from "@material-ui/icons";
import { extractErrorMessage } from "module/tools";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted";
import { FormControlLabel, Switch } from "@material-ui/core";

const TabGeneral = ({ structure, setStructure, masterValues, defaultLang, isEditable, errors }) => {
  const sj = structure.sj;
  const setSj = v => setStructure({ ...structure, sj: v });

  const compareThirdParty = compareFn => {
    var tp = structure.thirdParties.find(tp => !!tp.isMain);
    if (tp && tp.thirdParty && !compareFn(tp.thirdParty)) return <Trans>Structure_DifferentFromThirdPartyData</Trans>;

    return null;
  };

  return (
    <>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Structure_Part_General</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <GridContainer>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Structure_RaisonSocialeJade</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.raisonSociale}
                    warning={compareThirdParty(tp => tp.customLongName === sj.raisonSociale)}
                    onChange={e => setSj({ ...sj, raisonSociale: e.target.value })}
                    error={extractErrorMessage(errors, "SJ_RAISONSOCIALE", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Structure_RaisonSociale</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.raisonSocialeComplet}
                    warning={compareThirdParty(tp => tp.longName === sj.raisonSocialeComplet)}
                    onChange={e => setSj({ ...sj, raisonSocialeComplet: e.target.value })}
                    error={extractErrorMessage(errors, "SJ_RAISONSOCIALECOMPLET", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>LegalIdentifier</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.legalIdentifier}
                    warning={compareThirdParty(tp => tp.legalIdentifier === sj.legalIdentifier)}
                    onChange={e => setSj({ ...sj, legalIdentifier: e.target.value })}
                    error={extractErrorMessage(errors, "SJ_LEGALID", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>TaxCode</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.taxCode}
                    warning={compareThirdParty(tp => tp.taxCode === sj.taxCode)}
                    onChange={e => setSj({ ...sj, taxCode: e.target.value })}
                    error={extractErrorMessage(errors, "SJ_TAXCODE", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Structure_CodeActivite</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.codeActivite}
                    onChange={e => setSj({ ...sj, codeActivite: e.target.value })}
                    error={extractErrorMessage(errors, "SJ_ACTIVITE", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Structure_SJ_FormeJuridique</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.formeJuridique}
                    onChange={e => setSj({ ...sj, formeJuridique: e.target.value })}
                    error={extractErrorMessage(errors, "SJ_FORMJUR", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Structure_SJ_RCS</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.rcs}
                    onChange={e => setSj({ ...sj, rcs: e.target.value })}
                    error={extractErrorMessage(errors, "SJ_RCS", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Structure_SJ_OwnershipRate</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.ownershipRate}
                    onChange={e => setSj({ ...sj, ownershipRate: parseFloat(parseFloat(e.target.value).toFixed(2)) })}
                    error={extractErrorMessage(errors, "SJ_OWNERSHIPRATE", defaultLang)}
                    showErrorTooltip
                    size="small"
                    type={"number"}
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Structure_SJ_TurnoutRate</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.turnoutRate}
                    onChange={e => setSj({ ...sj, turnoutRate: parseFloat(parseFloat(e.target.value).toFixed(2)) })}
                    error={extractErrorMessage(errors, "SJ_TURNOUTRATE", defaultLang)}
                    showErrorTooltip
                    size="small"
                    type={"number"}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <GridContainer>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Line1</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.address_Line1}
                    warning={compareThirdParty(tp => tp.address_Line1 === sj.address_Line1)}
                    onChange={e => setSj({ ...sj, address_Line1: e.target.value })}
                    error={extractErrorMessage(errors, "SJ_ADDR_LINE1", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Line2</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <FieldText
                    isEditable={isEditable}
                    value={sj.address_Line2}
                    warning={compareThirdParty(tp => tp.address_Line2 === sj.address_Line2)}
                    onChange={e => setSj({ ...sj, address_Line2: e.target.value })}
                    error={extractErrorMessage(errors, "SJ_ADDR_LINE2", defaultLang)}
                    showErrorTooltip
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>City</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <CityZipAutocomplete
                    countryCode={sj.address_CountryCode}
                    zipCode={sj.address_ZipCode}
                    city={sj.address_City}
                    countyCode={sj.address_CountyCode}
                    stateCode={sj.address_StateCode}
                    onZipCityChange={(zipCode, city) => {
                      setSj({
                        ...sj,
                        address_ZipCode: zipCode,
                        address_City: city
                      });
                    }}
                    onCountyStateChange={countyState => {
                      setSj({
                        ...sj,
                        address_StateCode: countyState.stateCode,
                        address_CountyCode: countyState.countyCode
                      });
                    }}
                    isEditable={isEditable}
                    size="small"
                    defaultLang={defaultLang}
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>Country</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <CountryAutocomplete
                    countryCode={sj.address_CountryCode}
                    onChange={codeIso2 => {
                      if (sj.address_CountryCode !== codeIso2 && (sj.address_CountryCode === "FR" || codeIso2 === "FR"))
                        setSj({
                          ...sj,
                          address_ZipCode: null,
                          address_City: null,
                          address_CountryCode: codeIso2
                        });
                      else setSj({ ...sj, address_CountryCode: codeIso2 });
                    }}
                    defaultLang={defaultLang}
                    isEditable={isEditable}
                    size="small"
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <Muted>
                    <Trans>LatitudeLongitude</Trans>
                  </Muted>
                </GridItem>
                <GridItem xs={7} sm={7} md={7}>
                  <GpsAutocomplete
                    isEditable={isEditable}
                    source={sj}
                    setSource={c => {
                      setSj(c);
                    }}
                    showMap
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={sj.isGestionAdministrative}
                        disabled={!isEditable}
                        onChange={() =>
                          setSj({
                            ...sj,
                            isGestionAdministrative: !sj.isGestionAdministrative
                          })
                        }
                      />
                    }
                    label={
                      <Muted>
                        <Trans>Structure_SJ_IsGestionAdministrative</Trans>
                      </Muted>
                    }
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={sj.isGestionTechnique}
                        disabled={!isEditable}
                        onChange={() =>
                          setSj({
                            ...sj,
                            isGestionTechnique: !sj.isGestionTechnique
                          })
                        }
                      />
                    }
                    label={
                      <Muted>
                        <Trans>Structure_SJ_IsGestionTechnique</Trans>
                      </Muted>
                    }
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={sj.isGestionBudgetInvest}
                        disabled={!isEditable}
                        onChange={() =>
                          setSj({
                            ...sj,
                            isGestionBudgetInvest: !sj.isGestionBudgetInvest
                          })
                        }
                      />
                    }
                    label={
                      <Muted>
                        <Trans>Structure_SJ_IsGestionBudgetInvest</Trans>
                      </Muted>
                    }
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={sj.isSiegeFiliale}
                        disabled={!isEditable}
                        onChange={() => setSj({ ...sj, isSiegeFiliale: !sj.isSiegeFiliale })}
                      />
                    }
                    label={
                      <Muted>
                        <Trans>Structure_SJ_IsSiegeFiliale</Trans>
                      </Muted>
                    }
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={sj.isSuccursale}
                        disabled={!isEditable}
                        onChange={() => setSj({ ...sj, isSuccursale: !sj.isSuccursale })}
                      />
                    }
                    label={
                      <Muted>
                        <Trans>Structure_SJ_IsSuccursale</Trans>
                      </Muted>
                    }
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={sj.hasColasStaff}
                    disabled={!isEditable}
                    onChange={() =>
                      setSj({
                        ...sj,
                        hasColasStaff: !sj.hasColasStaff
                      })
                    }
                  />
                }
                label={
                  <Muted>
                    <Trans>Structure_SJ_HasColasStaff</Trans>
                  </Muted>
                }
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Domain />
            <Trans>Structure_Part_ThirdParties</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <PartThirdParties
            structure={structure}
            setStructure={setStructure}
            copyDataFromTp={data => {
              setSj({ ...sj, ...data });
            }}
            isEditable={isEditable}
            masterValues={masterValues}
            defaultLang={defaultLang}
            errors={errors}
          />
        </CardBody>
      </Card>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <LocationOn /> <Trans>Structure_Part_Locations</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <PartLocations structure={structure} setStructure={setStructure} isEditable={isEditable} defaultLang={defaultLang} errors={errors} />
        </CardBody>
      </Card>
    </>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(structureStyle)(TabGeneral);
